
import Slider from "react-slick";
import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Data from './Data';


const ProjetSlider = () => {
  const [items, setitems] = useState([]);

  useEffect(() => {
    console.log("Data.SliderData:", Data.SliderData);
    setitems(Data.SliderData);
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div>

      <Slider {...settings} className="project-slider">
        {items && items.map((val, index) => {
          return (
            <div key={index}>
             
                <img src={process.env.PUBLIC_URL + val.image} alt={val.alt} className="img-fluid" />
                {/* <div className='content'>
                <p>{val.type}</p>
                <strong>{val.name}</strong>
                <p>{val.detail}</p>
                
              </div> */}

              
            </div>
          )
        })}


      </Slider>
    </div>
  );
}

export default ProjetSlider