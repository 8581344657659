import React from 'react'
import { Col, Row } from 'react-bootstrap'
//import Hosting from '../src/Images/hosting.png'
//import Analyzing from '../src/Images/analyzing.png'
//import Delivery from '../src/Images/deliver.png'
import {BsSearch, BsTruck} from 'react-icons/bs'
import { BiAnalyse} from 'react-icons/bi'

const Strategy = () => {
  return (
    <div className="container mb-40 mt-40">
        <div className='disclaimer'>
          <p>
          PROCESS
          </p>
            <h2>how we works?</h2>
            <div className='bar bar-big'></div>
        </div>
        <Row>
          <Col md={4} className='strategies ' > 
          <div className='icon'>
            <BsSearch/>
              {/* <img src={Hosting} alt="icon" className='img-fluid'/> */}
            </div>
            <h5>Clarity in Vision</h5>
            <p>A comprehensive plan sets the stage by defining the project's purpose, objectives, and scope. It aligns every team member's focus on a shared vision, minimizing confusion and maximizing efficiency.</p>
          </Col>
          <Col md={4} className='strategies ' > 
          <div className='icon'>
            <BiAnalyse/>
              {/* <img src={Analyzing} alt="icon" className='img-fluid'/> */}
            </div>
            <h5>Project Analysis</h5>
            <p>With insights in hand, we formulate a strategic blueprint that outlines the project's objectives, tasks, timelines, and resources required. This serves as our North Star throughout the project journey.</p>
          </Col>

          <Col md={4} className='strategies ' > 
          <div className='icon'>
            <BsTruck/>
              {/* <img src={Delivery} alt="icon" className='img-fluid'/> */}
            </div>
            <h5>Deliver Result</h5>
            <p>In an era of diverse devices and browsers, our cross-platform testing ensures your website or app performs consistently across various platforms. From desktops to smartphones, iOS to Android, we meticulously examine every facet to guarantee a uniform user experience</p>
          </Col>
          
        </Row>
        
          
      
    </div>
  )
}

export default Strategy
