//import { Button } from '@material-ui/core';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Button, Form, Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {FaFacebookSquare, FaGithubSquare, FaInstagramSquare, FaTwitterSquare, FaMailBulk, FaPhoneAlt} from 'react-icons/fa';
import {FaRegEnvelope, FaMobileAlt} from "react-icons/fa";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_d4d7178', 'template_e9a7i3i', form.current, 'SEnEhbANb4_j1JlYX')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  // 


  // jsx starts here
  return (
    <>
      <Container>
      <div style={{ width: "100%", height: "max-content" }}>
        <div className='contact-first-page'>
          <section className=' contact-form col-md-7'>
            <fieldset style={{ padding: "1%" }}>
              <legend><h3>contact us</h3></legend>
              

              <Form ref={form} onSubmit={sendEmail}>
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formGridEmail">
                    <Form.Label>First Name </Form.Label>
                    <Form.Control type="text" placeholder="Your name" name="from_name" />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formGridEmail">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Last Name" name="l_name" />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formGridEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="from_email" />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formGridEmail">
                    <Form.Label>Phone  </Form.Label>
                    <Form.Control type="number" placeholder="Enter Your phone no." name="from_phone" />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message </Form.Label>
                    <Form.Control as="textarea" rows={3} name="message" />
                </Form.Group>

                <Button variant="primary" type="submit" onClick={()=>alert("we will reach you soon")}>
                  Submit
                </Button>
              </Form>
            </fieldset>
          </section>
                <section className='col contact-details'> 
                
                
                <Container>
                  <Row className='mb-30'>
                    <Col md ={12}>
                      <h4> Social media :</h4>                      
                    </Col>
                    <Col md ={12}>
                      <Link to="/" className='social'>
                      <FaFacebookSquare/>
                      </Link> 
                      <Link to="/" className='social'>
                     
                      <FaTwitterSquare/>
                      </Link> 

                    </Col>
                  </Row>
                  <Row className='mb-30'>
                    <Col md ={12}>
                      <h4> Contact Us :</h4>                      
                    </Col>
                    <Col md ={12}>
                     <p>
                     <a href="mailto:amt@techamt.com" className='contactdetails'><FaMailBulk/>  amt@techamt.com </a>
                      </p> 
                      <p>
                      <a href="tel:+917289949541" className='contactdetails'> <FaPhoneAlt/> +91 7289949541 </a>
                      </p>                       
                    </Col>
                  </Row>
                </Container>
                 
                </section>
        </div>
      </div>
      </Container>
    </>
  )
}

export default Contact;
