import React, { useEffect ,useState} from 'react'
import Data from './Data';
import Slider from "react-slick";
import image from '../src/Images/userprofile.png';
const Review = () => {
     const [Review,setreview]=useState([]);

     useEffect(()=>{
        setreview(Data.reviews);
     },[])
     const settings = {
        dots: false,
        infinite: true,
        arrows:false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className='container mb-40 mt-40'>
       
    <div className='disclaimer text-center'>
        <p> Customer feedback </p>
        <h2>Reviews</h2>
        <div className='bar bar-big'></div>
    </div>
    
     <div className='reviews'>
    
        <div className='review-container'>
        <Slider {...settings} className='news-container'>
            {Review.map((val,index)=>{
                return (
                    <>
                    <div className='review'>
                         <div className='client-image'>
                            <img src={image} width="100%" height="100%" alt='client'/>
                        </div> 

                        <div className='disclaimer'>
                            <h4>{val.name}</h4>
                            <h6>{val.designation} <small> {val.companyname}</small></h6>
                            
                            <p>"{val.comment}"</p>
                        </div>
                    </div>
                    </>
                )
            })}
            </Slider>
        </div>
        
    </div>
    </div>
  )
}

export default Review
