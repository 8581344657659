import React from 'react';
import AOS from 'aos';
import "/node_modules/aos/dist/aos.css"
import { Col, Container, Row } from 'react-bootstrap';
import {BsFillPeopleFill, BsLaptop, BsHeadset, BsCodeSquare} from 'react-icons/bs'
//import { FaUserTie, FaLaptopCode } from 'react-icons/fa';

//import Programmer from '../src/Images/programmer.png'

const About = () => {
    AOS.init();
    return (

        <>
            <div className="about-main-container" >
                <div className='about-first-page mb-40 mt-40'>
                    <div className='disclaimer '>
                        <p>what we are offering</p>
                        <h2>why to choose us</h2>
                        <div class="bar bar-big"></div>
                    </div>
                    <div className='detail-about-us container'>
                        <Row>
                            <div className='col-md-3 mb-20' data-aos="fade-left">
                                <div className='details'>
                                    <div className='icons'>
                                        < BsFillPeopleFill/>                                      
                                    </div>
                                    <h2>customer satisfaction</h2>

                                </div>

                            </div>
                            <div className=' col-md-3 mb-20' data-aos="fade-right">
                                <div className='details'>
                                    <div className='icons'>
                                        < BsLaptop/>                                        
                                    </div>
                                    <h2>Provide all kind of It solutions</h2>

                                </div>
                            </div>
                            <div className=' col-md-3 mb-20' data-aos="fade-right">
                                <div className='details'>
                                <div className='icons'>
                                    < BsHeadset/>
                                    </div>
                                    <h2>All it consultancy services</h2>

                                </div>
                            </div>
                            <div className='col-md-3 mb-20' data-aos="fade-left">
                                <div className='details'>
                                <div className='icons'>
                                    <BsCodeSquare/>                                    
                                    </div>
                                    <h2>expert developer</h2>

                                </div>
                            </div>
                        </Row>
                    </div>

                </div>
                <div className='about-second-page mt-40'>
                    <Container>
                        <Row className='align-items-center'>
                            <Col md={6}>
                                <div className='image' data-aos="fade-right">
                                    <img alt='loading' width="100%" height="100%" src="Images/finance.svg" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='detail-about-us-2'>
                                    <p>welcome to AMTTECH Solutions</p>
                                    <h1>are you rady to <span className='orange'> grow your buisness </span> </h1>
                                    <p>  We have the technology and industry expertise to develop solutions that can connect people and businesses</p>

                                    <p>  "TechAmt" is a growth-driving software development compnay. We aid businesses in creating a powerful
                                        digital presence,
                                        increasing the customer base, and growing of branding, marketing, and web design & development solutions.
                                        Our team
                                        of experts is carefully curated to provide the best solutions for needs in the digital landscape.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
export default About;