import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, BrowserRouter as Router  } from 'react-router-dom'
// import { Home } from '@mui/icons-material'
import Home from './Home'
import Services from './Services'
import About from './About'
import Contact from './Contact'
import Footer from './Footer'
import Carriers from './Carriers'
import Navigation from './Navbar';
import Blog from './Blog';
import Clients from './Clients';

const App = () => {
  return (
  <Router>  
    <>
      <Navigation/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/Services' element={< Services/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='clients' element={<Clients/>}/>
      </Routes>
      
      <Footer/>
    </>
    </Router>
  )
}

export default App
