import React, { useState, useEffect } from 'react'
//import {BsCodeSquare} from 'react-icons/fa'
import Data from './Data';
const Services = () => {
   
    const [service, setservices] = useState([]);
   
    useEffect(() => {
       
        setservices(Data.services);
    }, [])
    return (
        <>
            <div className='services-container container mb-40 mt-40'>
                <div className='disclaimer'>
                    <p>what we are offering</p>
                    <h2>services built specifically for your buisness</h2>
                </div>
                <div className='services-detail row'>
                    {service.map((val, index) => {
                        return (
                            <>
                                <div className='col-md-4 mb-20' data-aos="fade-left" key={index}>
                                    <div className='details'>
                                    <div className='icon'>
                                        <i className={val.icon}></i>
                                        {/* <img src={val.icon} alt='icons' /> */}
                                    </div>
                                    {/* <div className='sequence-number'>{val.sequence}</div> */}
                                    <h2 className='text-center'>{val.name}</h2>
                                    <p className='text-center'>{val.detail}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
            
        </>
    )
}

export default Services
