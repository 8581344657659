//import React, { useState } from 'react'
import About from './About';
import Services from './Services';
import Strategy from './Strategy';
//import News from './News';
import Clients from './Clients';
import Review from './Review';

const Home = () => {
 // const [Translate, settranslate] = useState(0);
  return (
    <>
       <div className='slider-container'>
        <div className='slider'>
          <div className='slider-pages slider-firt-page' >
            <div className='animation-container'>
              <div className='animation-content'>
                <div>
                  <h1>leading & innovative it solutions</h1>
                  <h1>for your buisness need</h1>
                  <p className='mt-30 mb-30'>I have 10+ Years of the experience in the software development, and I am delivering superior projects</p>
                </div>
                <div className='buttons'>
                   <a href='contact' className='button'> Get Started Now </a> 
                </div>
              </div>
            </div>
          </div>
         
         
        </div>
       
      </div> 

      <About/>
      <Services/>
      <Clients/>
      <Strategy/>
      <Review/>
      {/* <News/> */}
    </>
  )
}

export default Home
