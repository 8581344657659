import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import "/node_modules/aos/dist/aos.css"
import { Col, Container, Row } from 'react-bootstrap';
import axios from "axios";
import { Link } from 'react-router-dom';
const Blog = () => {
    const [newsdata, setNewsData] = useState([]);
    useEffect(() => {
        axios.get("https://newsapi.org/v2/top-headlines?sources=techcrunch&apiKey=f1910b3680634a47b83bb95cf4fb5a5f")
            .then((res) => {
                setNewsData(res.data.articles);
            })
    }, [])
    AOS.init();
    return (

        <>
            <div className="about-main-container" >

                <div className='mb-40 mt-40'>
                    {newsdata.map((post) => {
                        const { title, author, publishedAt, description, urlToImage,content,url } = post;

                        return (
                            <Container>
                                <Row className='blog-container'>
                                    <Col md={6}>
                                        <div className='image' data-aos="fade-right">
                                            <img alt='loading' width="100%" height="100%" src={urlToImage} />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='detail-about-us-2'>
                                            <p>{publishedAt}</p>
                                            <p><small>{author}</small></p>
                                            <h1>{title}</h1>                                            
                                            <p>{description}</p>
                                            <p>{content}</p>
                                            <p>For more info <Link to={url}> click here </Link>  </p>
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        )

                    })
                    }

                </div>
            </div>
        </>
    )
}
export default Blog;