
// slider imge
import slider1 from '../src/Images/logo.svg';
import slider2 from '../src/Images/propval-logo-n.png';
import slider3 from '../src/Images/brand-logo.png';
import slider4 from '../src/Images/logo1.png';
import slider5 from '../src/Images/logoTU.png';
import slider6 from '../src/Images/SMSnitilog.png';

const Data = {
    projects: [{ type: "marketting", name: "tech website solution", detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere maiores asperiores odio veniam sapiente pariatur voluptatem possimus nihil illum deserunt mollitia quibusdam cum hic eligendi ipsam et explicabo, expedita dolores ratione provident dignissimos perspiciatis sed at. Eum modi vitae iste velit delectus molestiae a non ratione, vero, accusamus assumenda temporibus.", image: "" }
        , { type: "marketting", name: "app platform integration", detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere maiores asperiores odio veniam sapiente pariatur voluptatem possimus nihil illum deserunt mollitia quibusdam cum hic eligendi ipsam et explicabo, expedita dolores ratione provident dignissimos perspiciatis sed at. Eum modi vitae iste velit delectus molestiae a non ratione, vero, accusamus assumenda temporibus.", image: "" }
        , { type: "marketting", name: "software tools development", detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere maiores asperiores odio veniam sapiente pariatur voluptatem possimus nihil illum deserunt mollitia quibusdam cum hic eligendi ipsam et explicabo, expedita dolores ratione provident dignissimos perspiciatis sed at. Eum modi vitae iste velit delectus molestiae a non ratione, vero, accusamus assumenda temporibus.", image: "" }
        , { type: "marketting", name: "tech webaites solution", detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere maiores asperiores odio veniam sapiente pariatur voluptatem possimus nihil illum deserunt mollitia quibusdam cum hic eligendi ipsam et explicabo, expedita dolores ratione provident dignissimos perspiciatis sed at. Eum modi vitae iste velit delectus molestiae a non ratione, vero, accusamus assumenda temporibus.", image: "" }
    ],

    services: [
        {
            icon: "fa fa-database",
            sequence: "01",
            name: "web hosting",
            detail: "Perfect for start-ups and small businesses, our shared hosting plans provide cost-effective solutions without compromising on quality."
        },

        {
            icon: "fas fa-keyboard",
           
            // icon: imge2,
            sequence: "02",
            name: "website development and  design",
            detail: "Website design and development require an understanding of brand, mobility, interactivity, color, and usability. Website is the most important lead generator for businesses."
        },
        
        {
            //icon: imge3,
            icon: "fa fa-mobile",
            sequence: "03",
            name: "mobile app development",
            detail: "As internet traffic increasingly shifts to mobile devices, businesses that embrace mobile app development gain a competitive edge by ensuring a consistent and compelling user experience across different platforms."
        },
        {
           // icon: imge4,
           
            icon: "fas fa-shopping-cart",
            sequence: "04",
            name: "e commerce solution",
            detail: "Transform your physical store into a digital powerhouse with our state-of-the-art POS solutions. Seamlessly manage in-store and online transactions, inventory synchronization, and customer data, providing a unified shopping experience across all touchpoints."
        },
        {
           // icon: imge5,
           icon: "fas fa-paint-brush",
            sequence: "05",
            name: "Graphics Design",
            detail: "Graphic design is the art and practice of planning and implementing ideas and experiences with visual and textual content. It can be physical or virtual and can include images, words, or graphics."
        },
        {
           
           
           icon: "fas fa-laptop-code",
            sequence: "06",
            name: "CMS Development",
            detail: " 'CMS (Content Management System)', We have professionals in custom CMS development for great and optimized websites. We begin with expertly customized content management and delivery applications."
        },
    ],
    reviews: [
        {
            // image: require('../src/Images/userprofile.png').default,
            name: "Hrushikesh",
            designation: "Sales Manager",
            companyname: "SMSNITI.COM",
            comment: "I want to express my heartfelt gratitude for your exceptional work on our website development project. Your expertise, timely contributions, and valuable insights were instrumental in creating a fantastic website. We are truly impressed with your professionalism and commitment. Your work has left a positive and lasting impression on us, and we would not hesitate to recommend your services to others looking for a proficient website developer. You have certainly set a high standard, and we look forward to the possibility of collaborating with you on future projects. Thank you for your outstanding service. "
        },
        {
           // image: require('../src/Images/userprofile.png').default,
            name: "Hrushikesh",
            designation: "Sales Manager",
            companyname: "SMSNITI.COM",
            comment: "I want to express my heartfelt gratitude for your exceptional work on our website development project. Your expertise, timely contributions, and valuable insights were instrumental in creating a fantastic website. We are truly impressed with your professionalism and commitment. Your work has left a positive and lasting impression on us, and we would not hesitate to recommend your services to others looking for a proficient website developer. You have certainly set a high standard, and we look forward to the possibility of collaborating with you on future projects. Thank you for your outstanding service. "
        },
        {
           // image: require('../src/Images/userprofile.png').default,
            name: "Advait Nandishwar",
            designation: "Sr.Executive",
            companyname: "NA",
            comment: "They did an outstanding job on my project. Their communication was excellent, and they completed the work on time and to my full satisfaction. I highly recommend him for any project."
        },
    ],
    News: [
        { 
            bg_img: "../images/blog1-390x290.jpg", 
            writer_img: "", 
            name: "kelvin clan", 
            title: "lets understand different types of data backups", 
            posteddate: "25/08/2021",
            description: "Digital Transformation in Healthcare in 2022: Lorem ipsum dolor sit amet consectet ",
            comments: ["comment1", "comment2"] 
        },
        { 
            bg_img: "../images/blog06-390x290.jpg",
            posteddate: "25/08/2021", 
            writer_img: "", 
            name: "kelvin clan", 
            description: "Digital Transformation in Healthcare in 2022: Lorem ipsum dolor sit amet consectet ",
            title: "lets understand different types of data backups", 
            comments: ["comment1", "comment2"] 
        },
        { 
            bg_img: "../images/blog01-390x290.jpg",  
            posteddate: "25/08/2021", 
            writer_img: "", 
            name: "kelvin clan", 
            title: "lets understand different types of data backups", 
            description: "Digital Transformation in Healthcare in 2022: Lorem ipsum dolor sit amet consectet ",
            comments: ["comment1", "comment2"] },
    ],
    SliderData: [
        {
            image: slider1,
            alt: "Slider1",
            url: "http://play.pillofun.com/login.php"

        },
        {
            image: slider2,
            alt: "Slider2",
            url: "http://traveluturn.com/"

        },
        {
            image: slider3,
            alt: "Slider3",
            url: "http://www.powertaal.com/",
        },
        {
            image: slider4,
            alt: "Slider3",
            url: "https://www.propval.co.uk/login.php"
        },
        {
            image: slider5,
            alt: "Slider3",
            url: "orgolove.com"
        },
        {
            image: slider6,
            alt: "Slider3",
            url: "smsniti.com"
        },
        

    ]
};
export default Data;