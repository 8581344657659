import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Logo from "../src/Images/Logo.png"
import {FaFacebookSquare, FaGithubSquare, FaInstagramSquare, FaTwitterSquare, FaMailBulk, FaPhoneAlt} from 'react-icons/fa';
//import VisiterCounter from './visitercounter';
const Footer = () => {
  return (
    <>
    <footer className='footer mt-40'>
      <Container>
       <Row>
        <Col md ={3}> 
        
       
          <div className='footer-logo mb-20'>
          <img src={Logo} alt='techamt' className='img-fluid'/>   
          </div>
        <p>
        Completely strategize client-cent Phosfluorescently iterate efficient
internal or organic.
        </p>
        <FaFacebookSquare/>
        <FaGithubSquare/>
        <FaInstagramSquare/>
        <FaTwitterSquare/>
        </Col>
        <Col md ={3}> <h4 className='mb-20'> Quick Links </h4> 
        <ul>
                <NavLink to="/" activeClassName="active-nav">
                  <li className='nav-items'>home</li>
                  </NavLink>
                <NavLink to="/about" activeClassName="active-nav">
                  <li className='nav-items'>about us</li>
                  </NavLink>
                <NavLink to="/services" activeClassName="active-nav">
                  <li className='nav-items'>services</li>
                  </NavLink>
                <NavLink to="/clients" activeClassName="active-nav">
                  <li className='nav-items'>our clients</li>
                  </NavLink>
                
                <NavLink to="/blog" activeClassName="active-nav">
                  <li className='nav-items'>blog</li>
                  </NavLink>
               
                <NavLink to="/contact" activeClassName="active-nav">
                  <li className='nav-items'>contact</li>
                  </NavLink>
            </ul>
        </Col>
        {/* <Col md ={3}> 
          <h4 className='mb-20'>Popular Post</h4> 
          <div className='d-flex justify-content-between mb-30'>
            <Col md={4}>
              <img src="../images/blog06-80x80.jpg" className='img-fluid'/>
            </Col>
            <Col md={8}>
              <h5>How can use our latest news by</h5>
              <p>October 22, 2020</p>
            </Col>
          </div>
          <div className='d-flex justify-content-between'>
            <Col md={4}>
              <img src="../images/blog06-80x80.jpg" className='img-fluid'/>
            </Col>
            <Col md={8}>
              <h5>How can use our latest news by</h5>
              <p>October 22, 2020</p>
            </Col>
          </div>
        </Col> */}
        <Col md ={3}> 
          <h4 className='mb-20'>Contact Info</h4> 
          <div className='mb-10 d-flex justify-content-start'>
              <Col md={3}>  <FaMailBulk/> </Col>
              <Col md={9} className='px-3'>
                <h5>Email</h5>
                <p><a href="mailto:amt@techamt.com"> amt@techamt.com </a></p>
              </Col>
          </div>

          <div className='mb-10 d-flex justify-content-start'>
              <Col md={3} >  <FaPhoneAlt/> </Col>
              <Col md={9} className='px-3'>
                <h5>Phone</h5>
                <p><a href="tel:+917289949541"> +91 7289949541 </a></p>
              </Col>
          </div>
        </Col>
        {/* <VisiterCounter/> */}
       </Row>
      </Container>
     
    </footer>
    <p className='text-center mb-0'>  &copy; 2023 All right reserved </p>
    </>
  )
}

export default Footer