import React from 'react'

import ProjetSlider from './Project';
import { Container } from 'react-bootstrap';

const Clients = () => {
  return (
    <div className='clients-main-container'>
      <div className='project-container'>
                <Container>
                <div className='disclaimer'>
                    <p>recent projects</p>
                    <h2>our recently completed projects</h2>
                    <div className='bar bar-big'></div>
                </div>
                <ProjetSlider />
                </Container>
               

            </div>
    </div>
  )
}

export default Clients;
