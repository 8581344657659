//import { TableRowsOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { GoMail } from "react-icons/go";
import {BsTelephone} from 'react-icons/bs'
import { Col, Container, Nav, Navbar, Row, } from 'react-bootstrap';
import Logo from "../src/Images/Logo.png"


const Navigation = () => {
  //const [Display, setdisplay] = useState("none");
  return (
    <>
      <div className='top-header'>
        <Container>
          <Row>
            <Col md={2}> <a href="mailto:amt@techamt.com"> <GoMail /> amt@techamt.com </a></Col>
            <Col md={2}> <a href="tel:+917289949541"> <BsTelephone /> +91 7289949541 </a> </Col>
          
         
          </Row>
        </Container>

      </div>
    
      <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container>
        <Navbar.Brand href="#home">
          <img src={Logo} alt='techamt' className='img-fluid'/>
          <p><span className='quality'>Quality</span> . Service . <span className='quality'>Result</span></p>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
        <Nav className="custom-menu">
            <NavLink to="/" active ClassName="active">Home</NavLink>
            <NavLink to="/about" active ClassName="active"> About Us </NavLink>
            <NavLink to="/services" active ClassName="active"> Services </NavLink>
            <NavLink to="/clients" active ClassName="active">Our Clients</NavLink>
            <NavLink to="/blog" active ClassName="active">Blog</NavLink>
            <NavLink to="/contact" active ClassName="active">Contact Us</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

     
     
    </>
  )
}

export default Navigation;